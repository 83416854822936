<template>
    <div id="news-edit">
        <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="标题">
                <el-input v-model="form.title"></el-input>
            </el-form-item>
            <el-form-item label="类型">
                <el-select v-model="form.newsType" placeholder="请选择">
                    <el-option v-for="newsType in Object.keys(NEWS_TYPE)" :key="newsType" :label="NEWS_TYPE[newsType]"
                        :value="newsType">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="内容">
                <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig"
                    :mode="mode" />
                <Editor style="height: 500px; overflow-y: hidden;" v-model="form.content" :defaultConfig="editorConfig"
                    :mode="mode" @onCreated="onCreated" />
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="createOrEdit(form)">保存</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>

import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { createOrEdit, detail } from './news.js'
import { NEWS_TYPE } from '../../util/constants'

export default {
    name: "news_edit",
    components: { Editor, Toolbar },
    data() {
        return {
            NEWS_TYPE,
            editor: null,
            toolbarConfig: {},
            editorConfig: {
                placeholder: '请输入内容...',
                MENU_CONF: {
                    uploadImage: {
                        server: '/api/pic/upload',
                        fieldName: "file",
                        customInsert(res, insertFn) {
                            insertFn(res.data.fileName, '', res.data.fileName)
                        },
                    },

                }
            },
            mode: 'simple', // or 'simple'

            editInfo: null,

            form: {
                id: null,
                enable: null,
                sortVal: null,
                title: null,
                newsType: null,
                content: null,
                postWebsiteIdList: [],
            }
        }
    },
    methods: {
        createOrEdit,
        onCreated(editor) {
            this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
        },

        loadEditInfo() {

        }
    },
    mounted() {
        // 模拟 ajax 请求，异步渲染编辑器
        setTimeout(() => {
            this.html = '<p>模拟 Ajax 异步设置内容 HTML</p>'
        }, 1500)
    },
    beforeDestroy() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁编辑器
    },

    created() {

        let id = this.$route.query.id;
        if (id != null) {
            detail(id).then(data => {
                this.form = data;
            });
        }
    }

};
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>